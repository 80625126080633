@font-face {
  font-family: 'icomoon';
  src:  url('../components/../../public/fonts/icomoon.eot?rmmcud');
  src:  url('../components/../../public/fonts/icomoon.eot?rmmcud#iefix') format('embedded-opentype'),
    url('../components/../../public/fonts/icomoon.ttf?rmmcud') format('truetype'),
    url('../components/../../public/fonts/icomoon.woff?rmmcud') format('woff'),
    url('../components/../../public/fonts/icomoon.svg?rmmcud#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-EQM-Send:before {
  content: "\e900";
  color: #3d4f54;
  font-size: large;
}
.icon-EQM-Tick:before {
  content: "\e901";
  color: #bed800;
}
.icon-EQM-Bell:before {
  content: "\e902";
  color: #3d4f54;
}
.icon-EQM-Profile:before {
  content: "\e903";
  color: #3d4f54;
}
.icon-EQM-Menu:before {
  content: "\e904";
  color: #3d4f54;
}
.icon-EQM-Dots:before {
  content: "\e905";
  color: #3d4f54;
}
.icon-Lock:before {
  content: "\e91a";
  color: #bed800;
}
.icon-EQM-Setting:before {
  content: "\e906";
  color: #fff;
}
.icon-EQM-SettingOnClick:before {
  content: "\e906";
  color: #bed800;
}
.icon-EQM-Pencil:before {
  content: "\e907";
  color: #fff;
}
.icon-EQM-Dashboard::after {
  content: "\e908";
  color: #fff;
}
.icon-EQM-DashboardOnClick::before {
  content: "\e908";
  color: #bed800;
}
.icon-EQM-Person:before {
  content: "\e909";
  color: #fff;
}
.icon-EQM-PersonOnClick:before {
  content: "\e909";
  color: #bed800;
}
.icon-EQM-Seeding:before {
  content: "\e90a";
  color: #fff;
}
.icon-EQM-SeedingOnClick:before {
  content: "\e90a";
  color: #bed800;
}
.icon-EQM-Transplating:before {
  content: "\e90b";
  color: #fff;
}
.icon-EQM-TransplatingOnClick:before {
  content: "\e90b";
  color: #bed800;
}
.icon-EQM-Harvesting:before {
  content: "\e90c";
  color: #fff;
}
.icon-EQM-HarvestingOnClick:before {
  content: "\e90c";
  color: #bed800;
}
.icon-EQM-Tasks:before {
  content: "\e90d";
  color: #fff;
}
.icon-EQM-TasksOnClick:before {
  content: "\e90d";
  color: #bed800;
}
.icon-EQM-Complians:before {
  content: "\e90e";
  color: #fff;
}
.icon-EQM-CompliansOnClick:before {
  content: "\e90e";
  color: #bed800;
}
.icon-EQM-Logistics:before {
  content: "\e90f";
  color: #fff;
}
.icon-EQM-LogisticsOnClick:before {
  content: "\e90f";
  color: #bed800;
}
.icon-EQM-Sale:before {
  content: "\e910";
  color: #fff;
}
.icon-EQM-SaleOnClick:before {
  content: "\e910";
  color: #bed800;
}
.icon-EQM:before {
  content: "\e911";
  color: #fff;
}
.icon-EQMOnClick:before {
  content: "\e911";
  color: #bed800;
}
.icon-EQM-Dell .path1:before {
  content: "\e912";
  color: rgb(89, 141, 0);
}
.icon-EQM-Dell .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(247, 247, 245);
}
.icon-EQM-Dell .path3:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(247, 247, 245);
}
.icon-EQM-Dell .path4:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(247, 247, 245);
}
.icon-EQM-Dell .path5:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(247, 247, 245);
}
.icon-EQM-Plus .path1:before {
  content: "\e917";
  color: rgb(89, 141, 0);
}
.icon-EQM-Plus .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(247, 247, 245);
}
.icon-EQM-InnerPlus:before {
  content: "\e919";
  color: #bed800;
}
