
.page-item.active .page-link {
    color: white !important;
    background-color: #3d4f54 !important;
    box-shadow: none;
    border-radius: 8px;
}
   .page-link {
       position: relative;
       display: block;
       padding: .5rem .75rem;
       margin-left: -1px;
       line-height: 1.25;
       color: black !important;
       background-color: #F7F7F5 !important;
       border: 4px solid #F7F7F5 !important;
       box-shadow: none;
       
    
   }
   .page-item .page-link {
       box-shadow: none;
   }

   